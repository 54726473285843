#JoinPage {
  width: 100%;
  height: 100vh;
}

#JoinPage .joinInfos {
  margin: 10px auto;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 9px;
  box-shadow: 4px 4px 0 rgb(0, 59, 143);
  background: rgb(0, 106, 255);
  width: 70%;
  min-height: 100px;
  max-width: 500px;
  min-width: 250px;
  fill: #fff;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

#JoinPage .joinInfos:active {
  background: rgb(0, 80, 193);
}

#JoinPage .joinInfos > div:nth-child(1) {
  width: 45px;
  height: 45px;
}

#JoinPage .joinInfos > div:nth-child(2) {
  padding: 5px;
}

#JoinPage .joinInfos > div:nth-child(2) > p:nth-child(2) {
  font-weight: bold;
}

#JoinPage .players {
  display: table;
  margin: 0 auto;
  width: 70%;
  max-width: 500px;
  min-width: 250px;
}

#JoinPage .players > div {
  display: table-row;
  border-collapse: collapse;
}

#JoinPage .players > div > div {
  display: table-cell;
  padding: 5px;
  text-align: center;
}

#JoinPage .players > div:nth-child(even) {
  background: rgba(177, 209, 255, 0.397);
}

#JoinPage .players .headerCell {
  font-weight: bold;
  text-align: center;
}

#JoinPage .GameBtn {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

#JoinPage button {
  width: 70%;
  height: 40px;
  max-width: 500px;
  min-width: 250px;
  background: rgb(0, 106, 255);
  border: none;
  border-radius: 9px;
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  outline: none;
}

#JoinPage button:active {
  background: rgb(0, 59, 143);
}

#JoinPage .waiting {
  width: 100%;
  height: 100vh;
  display: block;
  text-align: center;
  font-weight: bold;
}

#JoinPage .waiting p {
  padding: 10px 0;
}

#JoinPage .cubes {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  border-radius: 10px;
  background: rgb(0, 106, 255);
}

#JoinPage .cubesHolder {
  position: relative;
  width: 100%;
  background: #fff;
}

#JoinPage .selectedCubes {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#JoinPage .selectedCubesHolder {
  position: relative;
  padding: 10px;
  width: 100%;
}

#JoinPage .cubesHolder {
  width: 100%;
  padding: 10px;
  position: relative;
  background: #fff;
}

#JoinPage .newGameHolder {
  display: flex;
}

#JoinPage .NewGameBtns {
  width: 50%;
  padding: 10px;
}

.Controls {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#JoinPage .ControlsBtn {
  max-width: 100px;
  min-width: none;
}

.newGameHolder .PlayerName{
  text-align: center;
}

@media (max-width: 769px) {
  .NewGameBtns {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
  }

  #JoinPage .NewGameBtns {
    width: 100%;
    max-width: 100%;
  }

  .Controls {
    display: block;
  }

  #JoinPage button {
    width: 100%;
    max-width: 100%;
    min-width: none;
  }

  #JoinPage .ControlsBtn {
    width: 100%;
    max-width: 100%;
    min-width: none;
  }
}
