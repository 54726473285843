*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{
    background-color: rgb(0, 106, 255, 0);
}

::-webkit-scrollbar-thumb{
    border: 3px solid #fff;
    border-radius: 5px;
    background-color: #161616;
}


.App{
    display: flex;
    flex-wrap: wrap;
}

.PiecesPlace{
    width: 50%;
}

.PiecesHolder{
    position: relative;
}

@media (max-width: 769px) {
    .App{
        display: block;
        flex-wrap: nowrap;
        height: 100vh;
        overflow: hidden;

    }

    .PiecesPlace{
        width: 100%;
    }

    .ExpandBtn{
        position: relative;
        width: 100%;
    }

    .ExpandBtn .ShowMore{
        margin: auto;
        width: 90%;
        border-radius: 9px;
    }
    
    .ShowMore > div {
        height: 100%;
        transition: 0.5s;
    }

    .ExpandBtn .ShowSelected{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 45px;
        border: 2px solid rgb(0, 59, 143);
        border-radius: 9px;
        overflow: hidden;
    }

    .PiecesHolder{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: #fff;
        transition: 0.5s;
    }
}
