#LoginPage {
  width: 100%;
  height: 100vh;
  display: flex;
}

.PageDivider {
  width: fit-content;
  margin: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #fff;
  padding: 10px;
}

.BasicInfos {
  position: relative;
  width: fit-content;
  max-width: 400px;
  padding: 10px;
  border-radius: 10px;
  background: rgb(0, 106, 255);
}

.BasicInfos h1 {
    width: 100%;
  padding: 5px 0;
  max-width: 400px;
  min-width: 250px;
  color: #fff;
  font-size: 1.5em;
  text-shadow: 5px 5px 0 rgb(0, 59, 143);
}

.BasicInfos input {
  width: 100%;
  height: 40px;
  max-width: 400px;
  min-width: 250px;
  padding: 9px;
  border-radius: 9px;
  border: 3px solid rgb(0, 106, 255);
  outline: none;
  font-size: 1em;
  transition: 0.3s;
}

.BasicInfos input:focus {
  border-color: rgb(0, 59, 143);
  transition: 0.3s;
}

.BasicInfos .nextBtn {
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  padding: 3px;
  height: 40px;
  border: none;
  border-radius: 9px;
  background: rgb(0, 59, 143);
  fill: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.BasicInfos .nextBtn:active {
  background: rgb(0, 44, 106);
}

.BasicInfos .back {
  position: absolute;
  top: -50px;
  left: 0;
  width: 40px;
  height: 40px;
  transform: rotate(180deg);
  border-radius: 50%;
  background: #fff;
  border: 3px solid rgb(0, 59, 143);
  fill: rgb(0, 59, 143);
  cursor: pointer;
  outline: none;
  transition: 0.3s;
}

.BasicInfos .back:active {
  border-color: rgb(0, 44, 106);
  fill: rgb(0, 44, 106);
}

.BasicInfos .chooseGameMode {
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  padding: 3px;
  height: 40px;
  border: none;
  border-radius: 9px;
  background: rgb(0, 59, 143);
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.BasicInfos .chooseGameMode:active {
  background: rgb(0, 44, 106);
}
