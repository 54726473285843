#normalsPlace{
    display: flex;
    align-items: center;
    justify-content: center;
}


#normals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
}