#SpecialsHolder{
    position: relative;
    padding: 10px;
    width: 100%;
}

#SpecialsPlace{
    position: relative;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow-X: auto;
    display: flex;
    background: rgb(0, 106, 255);
}

#SpecialsHolder .SpecialsLeft{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 25px;
    height: 25px;
    z-index: 2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 2px solid rgb(0, 59, 143);
    background: #fff;
}

#SpecialsPlace::-webkit-scrollbar{
    height: 10px;
}

#SpecialsPlace::-webkit-scrollbar-track{
    background-color: rgb(0, 106, 255, 0);
    margin: 0 10px;
}

#SpecialsPlace::-webkit-scrollbar-thumb{
    border: 3px solid rgb(0, 106, 255);
    border-radius: 5px;
    background-color: #fff;
}

#Specials{
    border-radius: 9px;
   
    display: flex;
}

