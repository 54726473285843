.CountTable{
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    background: rgb(0, 106, 255);
}

.CountTable .CountItem{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000;
    border-radius: 5px;
    overflow: hidden;
}


.CountItem .CountIcon{
    fill: #000;
    stroke:#000;
}

.CountItem .CountValue{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    background: #fff;
}