.cell {
    display: table-cell;
    position: relative;
    height: 65px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    /* border-color: rgb(37, 171, 255); */
}

.cell .object {
    position: relative;
    width: 100%;
    height: 100%;
}

.droppedpiece{
    position: absolute;
    width: 100%;
    height: 100%;
}

.round {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.7em;
}