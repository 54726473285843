.SpecialItem {
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SpecialItem > div {
  position: relative;
  border-radius: 9px;
  box-shadow: 8px 8px 0 rgb(0, 59, 143);
  outline: none;
}

.SpecialItem > div > div{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 9px;
}

.SpecialItem svg {
  border: 2px solid rgb(0, 59, 143);
  border-radius: 9px;
}

@media (max-width: 769px) {
  .SpecialItem {
    min-width: 35px;
    min-height: 35px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
