#controls {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 70px;
}

.controlItem {
  width: 100px;
  height: 70%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  fill: rgb(0, 0, 0);
}

.controlItem:active {
  background: rgb(0, 106, 255);
  fill: rgb(255, 255, 255);
}

.SettingsIcon {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.SettingsIcon > svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
